import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import '../assets/scss/theme.scss'
import Welcome from '../pages/welcome'

const isBrowser = typeof window !== 'undefined'
const index = () => {
  return (
    <>
      <main className="main" id="main">
        {isBrowser ? <Welcome /> : null}
      </main>
    </>
  )
}

export default index
